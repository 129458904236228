<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  ><path
    fill="currentColor"
    d="M23.89 8.5a1.74 1.74 0 0 0-.52-1.24L16.74.62a1.76 1.76 0 0 0-2.48 0L7.84 7.05a6.99 6.99 0 0 0-6.6 1.43 1.74 1.74 0 0 0-.14 2.6l5.29 5.3-3.76 3.75a.89.89 0 0 0 0 1.24.9.9 0 0 0 1.24 0l3.75-3.76 5.28 5.27c.32.33.77.51 1.23.52h.12a1.78 1.78 0 0 0 1.28-.7c2.15-2.86 1.94-5.18 1.45-6.57l6.4-6.4a1.74 1.74 0 0 0 .5-1.23Zm-8.58 6.81a.9.9 0 0 0-.16 1.02c1.04 2.07-.2 4.22-1.02 5.32L2.33 9.85A5.57 5.57 0 0 1 5.9 8.5c.96 0 1.73.32 1.78.35a.9.9 0 0 0 1.02-.16l6.81-6.83 6.64 6.64-6.83 6.81Z"
  /></svg>
</template>
